// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tn d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_tp d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_tq d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_tr d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_ts d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_tt d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_tv d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rW q_rW";
export var heroExceptionNormal = "v_rX q_rX";
export var heroExceptionLarge = "v_rY q_rY";
export var Title1Small = "v_rx q_rx q_q2 q_q3";
export var Title1Normal = "v_ry q_ry q_q2 q_q4";
export var Title1Large = "v_rz q_rz q_q2 q_q5";
export var BodySmall = "v_rM q_rM q_q2 q_rl";
export var BodyNormal = "v_rN q_rN q_q2 q_rm";
export var BodyLarge = "v_rP q_rP q_q2 q_rn";